//==============================================================================
// PDP Kit Contents
//
// Displays a list of items included in a kit.
//==============================================================================
import * as React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { getCatalogId } from '@msdyn365-commerce/core';

import { clsHelper } from '../../utilities/class-name-helper';
import { attrNames } from '../../utilities/global-constants';
import { convertProductAttributes, AttributesWithMetadata } from '../../utilities/data-attribute-parser';

import { ProductSearchResult, ProductSearchCriteria } from '@msdyn365-commerce/retail-proxy';
import { searchByCriteriaAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';

import { IPdpKitContentsData } from './pdp-kit-contents.data';
import { IPdpKitContentsProps } from './pdp-kit-contents.props.autogenerated';

//==============================================================================
// INTERFACES
//==============================================================================
export interface ContentsMap {
    kit: string;
    itemId: string;
    items: ItemMap[];
}

export interface ItemMap {
    itemId: string;
    productName?: string;
    productPath?: string;
}

//==============================================================================
// CLASS NAME UTILITY
//==============================================================================
const BASE_CLASS = 'pdp-kit-contents';
const cls = (fragment?: string) => clsHelper(BASE_CLASS, fragment);

//==============================================================================
// CLASS DEFINITION
//==============================================================================
/**
 * PdpKitContents component
 * @extends {React.Component<IPdpKitContentsProps<IPdpKitContentsData>>}
 */
//==============================================================================
@observer
class PdpKitContents extends React.Component<IPdpKitContentsProps<IPdpKitContentsData>> {
    //==========================================================================
    // VARIABLES
    //==========================================================================
    @observable private productList: ContentsMap[] = [];

    //==========================================================================
    // PUBLIC METHODS
    //==========================================================================
    //------------------------------------------------------
    // Invoked immediately after component is mounted
    //------------------------------------------------------
    public componentDidMount(): void {
        this._getProductList(attrNames.kitContents);
    }

    //------------------------------------------------------
    // Render function
    //------------------------------------------------------
    public render(): JSX.Element | null {
        const { config } = this.props;

        // If product list cannot be obtained, do not render module
        if (!this.productList) {
            return null;
        }

        return (
            <div className={classnames(BASE_CLASS, config.className)}>
                {this.productList.map((kit, index) => {
                    return (
                        <div className={cls('kit')} key={index}>

                            {/* Kit name */}
                            <div className={cls('heading')}>
                                {kit.kit}
                            </div>

                            {/* Kit contents table */}
                            <div className={cls('table')}>
                                {/* Table header row */}
                                <div className={cls('table-headers')}>
                                    {/* Item ID */}
                                    <div className={classnames(cls('table-header'), 'id')}>
                                        {config.labelItemId}
                                    </div>
                                    {/* Product name */}
                                    <div className={classnames(cls('table-header'), 'name')}>
                                        {config.labelItemName}
                                    </div>
                                </div>

                                {/* Table rows */}
                                {kit.items?.map((item, itemIndex) => {
                                    return (
                                        <div className={cls('table-row')} key={itemIndex}>
                                            {/* Item ID */}
                                            <div className={classnames(cls('table-cell'), 'id')}>
                                                {item.itemId}
                                            </div>
                                            {/* Product name */}
                                            <div className={classnames(cls('table-cell'), 'name')}>
                                                <a href={item.productPath} aria-label={item.productName}>
                                                    {item.productName}
                                                </a>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    //==========================================================================
    // PRIVATE METHODS
    //==========================================================================
    //------------------------------------------------------
    // Parse kit contents data into a friendlier format
    //
    // Ex input data: 'American Republic DVD with Books
    //                 (4th ed.)|450635:515841,298380,
    //                 298364,434506,433656,418327|English 8
    //                 DVD with Books|523092:523093,…'
    //------------------------------------------------------
    private readonly _parseContentsData = (contentsData: string): ContentsMap[] => {
        const splitData = contentsData.split('|');
        const parsedData = [];

        for (let i = 0; i < splitData.length; i+=2) {
            const separatedItemData = splitData[i+1].split(':');

            parsedData.push({
                kit: splitData[i],
                itemId: separatedItemData[0],
                items: this._parseItemsData(separatedItemData[separatedItemData.length - 1]) || []
            });
        }

        return parsedData;
    };

    //------------------------------------------------------
    // Parse kit items data into a friendlier format
    //
    // Ex input data: '515841,298380,298364'
    //------------------------------------------------------
    private readonly _parseItemsData = (itemsData: string): ItemMap[] => {
        return itemsData.split(',').map(property => {
            return {
                itemId: property
            };
        });
    };

    //------------------------------------------------------
    // Get converted product attributes
    //------------------------------------------------------
    private readonly _getConvertedAttributes = (): AttributesWithMetadata | undefined => {
        const { data } = this.props;
        const productAttributes = data.productSpecificationData?.result;
        return productAttributes && convertProductAttributes(productAttributes);
    };

    //------------------------------------------------------
    // Get product list with parsed properties
    //------------------------------------------------------
    private readonly _getProductList = async (attribute: string): Promise<void> => {
        const convertedAttributes = this._getConvertedAttributes();
        const kitAttribute = convertedAttributes && convertedAttributes[attribute] as string;
        const parsedAttributes = kitAttribute && this._parseContentsData(kitAttribute);

        const kitPromises = parsedAttributes && parsedAttributes.map(async kit => {
            const productItemIds = kit.items.map(item => item.itemId);
            const productList = productItemIds && await this._getProducts(productItemIds);

            const productResult = productList && productList.map((item: ProductSearchResult) => {
                const itemId = item.ItemId!;
                const productName = item.Name;
                const productPath = this._getProductPath(item);
                return {itemId, productName, productPath};
            });

            return {...kit, items: productResult};
        });

        // Wait for all requests to complete
        const kitResult = kitPromises && await Promise.all(kitPromises);

        this.productList = kitResult || [];
    };

    //------------------------------------------------------
    // Get products from item IDs
    //------------------------------------------------------
    private readonly _getProducts = async (itemIds: string[]): Promise<ProductSearchResult[]> => {
        const { context } = this.props;

        const itemList = itemIds?.map(itemId => ({ ItemId: itemId }));

        // Build a ProductSearchCriteria object
        const criteria: ProductSearchCriteria = {
            ItemIds: itemList,
            Context: {
                ChannelId: context.request.apiSettings.channelId,
                CatalogId: getCatalogId(context.request)
            }
        };

        try {
            // Fetch search results
            const searchResults = await searchByCriteriaAsync({ callerContext: context.actionContext }, criteria);
            return searchResults;
        } catch (error) {
            if (context.telemetry) {
                context.telemetry.exception(error as Error);
                context.telemetry.debug('Unable to find products');
            }
        }

        return [];
    };

    //------------------------------------------------------
    // Format product URL path from product rec ID
    //------------------------------------------------------
    private readonly _getProductPath = (product: ProductSearchResult): string | undefined => {
        return `/${String(product.RecordId)}.p`;
    };
}

export default PdpKitContents;
